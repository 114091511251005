import React, {useEffect, useState} from 'react'
import './FormFieldQuoteCarfacil.css'
import { useMediaQuery } from 'react-responsive';
import CustomTextInput from '../../../customTextInput/CustomTextInput';
import SelectorSearcher from '../../../selectorSearcher/SelectorSearcher';
import MainButton from '../../../MainButton/MainButton';
import { 
  actionGetVersionList,
  actionGetLocalidadList
} from '../../../../../actions';

let isTabletOrMobile = false;
const FormFieldQuoteCarfacil = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  //Inputs
  const [año, setAño] = useState<string>('')
  const [marcaModelo, setMarcaModelo] = useState<string>('')
  const [version, setVersion] = useState<any>(null)

  const [ceroKm, setCeroKm] = useState<boolean>(false)

  const [tipoUso, setTipoUso] = useState<any>(null)
  const [gnc, setGnc] = useState<any>(null)
  const [gncSuma, setGncSuma] = useState<string>('')

  const [provincia, setProvincia] = useState<any>(null)
  const [localidad, setLocalidad] = useState<any>(null)
  const [codigoPostal, setCodigoPostal] = useState<string>('')

  const [tipoPersona, setTipoPersona] = useState<any>(null)
  const [iva, setIva] = useState<any>(null)

  const [tipoDocumento, setTipoDocumento] = useState<any>(null)
  const [numero, setNumero] = useState<string>('')
  const [genero, setGenero] = useState<any>(null)

  const [maBonificacion, setMaBonificacion] = useState<any>(null)
  const [scBonificacion, setScBonificacion] = useState<any>(null)

  //Lists
  const [versionList, setVersionList] = useState<any>(null)

  const [tipoUsoList, setTipoUsoList] = useState<any>(null)
  const [gncList, setGncList] = useState<any>(null)

  const [provinciaList, setProvinciaList] = useState<any>(null)
  const [localidadList, setLocalidadList] = useState<any>()

  const [tipoPersonaList, setTipoPersonaList] = useState<any>(null)
  const [ivaList, setIvaList] = useState<any>(null)

  const [tipoDocumentoList, setTipoDocumentoList] = useState<any>(null)
  const [generoList, setGeneroList] = useState<any>(null)

  const [maBonificacionList, setMaBonificacionList] = useState<any>(null)
  const [scBonificacionList, setScBonificacionList] = useState<any>(null)

  //OTHER
  const [versionListLoader, setVersionListLoader] = useState<boolean>(false)
  const [localidadListLoader, setLocalidadListLoader] = useState<boolean>(false)
  const [timeoutOne, timeoutChangeOne] = useState<any>(0);
  const [timeoutTwo, timeoutChangeTwo] = useState<any>(0);

  useEffect(() => {
    if(props.quoteData) {
      setTipoUsoList(props.quoteData?.filter((a: any) => a.codigo === "35_caruse")[0]?.defaultValues)
      setGncList(props.quoteData?.filter((a: any) => a.codigo === "36_gnc")[0]?.defaultValues)

      setProvinciaList(props.quoteData?.filter((a: any) => a.codigo === "40_provincesc")[0]?.defaultValues)

      setTipoPersonaList(props.quoteData?.filter((a: any) => a.codigo === "48_tipo_persona")[0]?.defaultValues)
      setIvaList(props.quoteData?.filter((a: any) => a.codigo === "37_ivaafip")[0]?.defaultValues)

      setTipoDocumentoList(props.quoteData?.filter((a: any) => a.codigo === "49_tipo_documento")[0]?.defaultValues)
      setGeneroList(props.quoteData?.filter((a: any) => a.codigo === "38_genero")[0]?.defaultValues)

      setMaBonificacionList(props.quoteData?.filter((a: any) => a.codigo === "46_bonificacion")[0]?.config?.mercantil)
      setScBonificacionList(props.quoteData?.filter((a: any) => a.codigo === "46_bonificacion")[0]?.config?.sancristobal)
    }
  }, [props.quoteData])

  useEffect(() => {
    if (props.previousQuoteData?.previousQuestions?.length !== 0 && props.previousQuoteData?.previousQuestions?.length !== undefined && maBonificacionList && scBonificacionList) {
      setAño(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "32_car_year")[0]?.label)
      setMarcaModelo(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "30_car_query")[0]?.label?.replace(/"/g, ''))
      setVersion({
        infoauto: parseInt(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "33_car_version")[0]?.answerId),
        nombre: props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "33_car_version")[0]?.label?.replace(/"/g, '')
      })

      setCeroKm(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "47_cero_km")[0]?.label === "true")

      setTipoUso({
        label: props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "35_caruse")[0]?.label,
        value: parseInt(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "35_caruse")[0]?.answerId)
      })
      setGnc({
        label: props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "36_gnc")[0]?.label,
        value: parseInt(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "36_gnc")[0]?.answerId)
      })

      setProvincia(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "40_provincesc")[0]?.label?.replace(/"/g, ''))

      let url = props.quoteData?.filter((a: any) => a.codigo === "39_locations")[0]?.config?.url?.replace("{input}", props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "39_locations")[0]?.label);
      actionGetLocalidadList(url, setLocalidadList, setLocalidadListLoader, setLocalidad, props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "39_locations")[0]?.label)
      
      setCodigoPostal(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "34_postal_code")[0]?.label?.replace(/"/g, ''))

      setIva({
        label: props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "37_ivaafip")[0]?.label,
        value: parseInt(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "37_ivaafip")[0]?.answerId)
      })
      setNumero(props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "41_documentinsured")[0]?.label?.replace(/"/g, ''))
      setGenero({
        label: props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "38_genero")[0]?.label,
        value: props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "38_genero")[0]?.answerId?.replace(/"/g, '')
      })

      const jsonString = props.previousQuoteData?.previousQuestions?.filter((a: any) => a.questionId === "46_bonificacion")[0]?.answerId
      let jsonObject: any;
      try {
        jsonObject = JSON.parse(jsonString); 
      } catch (error) {
        
      }
      const mercantilValue = jsonObject?.mercantil;
      const sancristobalValue = jsonObject?.sancristobal;
      setMaBonificacion({
        label: maBonificacionList?.filter((a: any) => a.value == mercantilValue)[0]?.label,
        value: mercantilValue
      })
      setScBonificacion({
        label: scBonificacionList?.filter((a: any) => a.value == sancristobalValue)[0]?.label,
        value: sancristobalValue
      })
    }
  }, [props.previousQuoteData, maBonificacionList, scBonificacionList])

  useEffect(() => {
    if(gncList) setGnc(gncList?.filter((a: any) => a.value == 0)[0])
  },[gncList])

  useEffect(() => {
    if(tipoUsoList) setTipoUso(tipoUsoList?.filter((a: any) => a.value == 1)[0])
  },[tipoUsoList])

  useEffect(() => {
    if(tipoPersonaList) setTipoPersona(tipoPersonaList?.filter((a: any) => a.value == 1)[0])
  },[tipoPersonaList])

  useEffect(() => {
    if(ivaList) setIva(ivaList?.filter((a: any) => a.value == 5)[0])
  },[ivaList])

  useEffect(() => {
    if(tipoDocumentoList) setTipoDocumento(tipoDocumentoList?.filter((a: any) => a.value == "DU")[0])
  },[tipoDocumentoList])

  useEffect(() => {
    if(maBonificacionList) setMaBonificacion(maBonificacionList?.filter((a: any) => a.value == 23)[0])
  },[maBonificacionList])

  useEffect(() => {
    if(scBonificacionList) setScBonificacion(scBonificacionList?.filter((a: any) => a.value == -8)[0])
  },[scBonificacionList])

  useEffect(() => {
    if(año?.length === 4 && marcaModelo !== '') {
      setVersionListLoader(true)
      setVersionList(null)
      if (timeoutOne) timeoutChangeOne(clearTimeout(timeoutOne));
      timeoutChangeOne(
        setTimeout(() => {
          actionGetVersionList(setVersionList, año, marcaModelo, setVersionListLoader)
        }, 3000)
      );
    } else {
      setVersionListLoader(false)
      timeoutChangeOne(clearTimeout(timeoutOne));
    }
  }, [año, marcaModelo])

  useEffect(() => {
    if(tipoPersona?.value === 2) {
      setTipoDocumento(tipoDocumentoList?.filter((a: any) => a.value == "CUIT")[0])
      setIva(ivaList?.filter((a: any) => a.value == 1)[0])
    }
  }, [tipoPersona])

  const loadLocalidadList = (input: string) => {
    setLocalidadListLoader(true)
    setLocalidadList(null)
    if (timeoutTwo) timeoutChangeTwo(clearTimeout(timeoutTwo));
    timeoutChangeTwo(
      setTimeout(() => {
        let url = props.quoteData?.filter((a: any) => a.codigo === "39_locations")[0]?.config?.url?.replace("{input}", input);
        actionGetLocalidadList(url, setLocalidadList, setLocalidadListLoader, null, null)
      }, 3000)
    );
  }

  const validateYear = (year: any) => {
    if (year?.length === 4 && parseInt(year) <= new Date().getFullYear()) return true
    return false
  }

  const isDisabled = () => {
    return (
      !validateYear(año) || marcaModelo === '' || !version ||
      !tipoUso || !gnc || (gnc?.value !== 0 && gncSuma === '' ) ||
      !provincia || !localidad || codigoPostal === '' ||
      !tipoPersona || !iva ||
      !tipoDocumento || !validDocument(numero, tipoDocumento?.label) || !genero ||
      !maBonificacion || !scBonificacion
    )
  }

  const isCuit = (tipo: any) => {
    return tipo === 'CUIT'
  }

  const validDocument = (numero: string, tipo: any) => {
    if(isCuit(tipo)) return numero?.length === 11;
    return numero?.length === 8
  }

  return (
    <div className="formFieldQuoteCarfacil-container">
      <div className="formFieldQuoteCarfacil-container-section">
        <h6 className="formFieldQuoteCarfacil-container-section-title bold">Datos del vehículo</h6>
        <div className="formFieldQuoteCarfacil-container-section-inputs">
          <CustomTextInput
            label='Año'
            changeText={(e: string) => {
              setCeroKm(false)
              setAño(e.replace(/[^0-9]+/g, ''))
            }}
            value={año}
            required
            maxLength={4}
            success={validateYear(año)}
            error={año !== '' && !validateYear(año)}
            errorMessage='Año inválido'
            placeholder='Ingresá el año'
          />
          <CustomTextInput
            label='Marca/modelo'
            changeText={(e: string) => setMarcaModelo(e)}
            value={marcaModelo}
            required
            success={marcaModelo !== ''}
            placeholder='Ingresá la marca y el modelo'
          />
          <SelectorSearcher
            label='Versión'
            required
            id='Versión'
            list={versionList}
            onValueSelected={(a: any) => setVersion(a)}
            selectedItem={version?.nombre}
            placeholder='Seleccioná la versión'
            deleteSelectedItem={() => setVersion(null)}
            disabled={!versionList}
            loading={versionListLoader}
          /> 
          {año === new Date().getFullYear().toString() ? 
            <div className="formFieldQuoteCarfacil-container-section-inputs-ceroKm">
              <div className="formFieldQuoteCarfacil-container-section-inputs-ceroKm-box" onClick={() => setCeroKm(!ceroKm)}>
                <div 
                  className="formFieldQuoteCarfacil-container-section-inputs-ceroKm-box-inner"
                  style={{ transform: ceroKm ? `scale(${1})` : `scale(${0})` }}
                >
                  <span className="material-symbols-outlined" style={{ color: 'var(--white)', fontSize: 18 }}>check</span>
                </div>
              </div>
              <b className="formFieldQuoteCarfacil-container-section-inputs-ceroKm-text bold medium">Vehículo 0 Km</b>
            </div>
            :
            <div className="formFieldQuoteCarfacil-container-section-inputs-ceroKm" style={{ visibility: 'hidden'}}>
              <div className="formFieldQuoteCarfacil-container-section-inputs-ceroKm-box" onClick={() => setCeroKm(!ceroKm)}>
                <div 
                  className="formFieldQuoteCarfacil-container-section-inputs-ceroKm-box-inner"
                  style={{ transform: ceroKm ? `scale(${1})` : `scale(${0})` }}
                >
                  <span className="material-symbols-outlined" style={{ color: 'var(--white)', fontSize: 18 }}>check</span>
                </div>
              </div>
              <b className="formFieldQuoteCarfacil-container-section-inputs-ceroKm-text bold medium">Vehículo 0 Km</b>
            </div>
          }
          <SelectorSearcher
            label='Tipo de uso'
            required
            id='Tipo de uso'
            list={tipoUsoList}
            onValueSelected={(a: any) => setTipoUso(a)}
            selectedItem={tipoUso?.label}
            placeholder='Seleccioná el tipo de uso'
            deleteSelectedItem={() => setTipoUso(null)}
            disabled={!tipoUsoList}
          /> 
          <SelectorSearcher
            label='GNC'
            required
            id='GNC'
            list={gncList}
            onValueSelected={(a: any) => setGnc(a)}
            selectedItem={gnc?.label}
            placeholder='Seleccioná el gnc'
            deleteSelectedItem={() => setGnc(null)}
            disabled={!gncList}
          /> 
          {(gnc?.value !== 0 && gnc) ? 
            <CustomTextInput
              label='GNC - Suma asegurada'
              changeText={(e: string) => setGncSuma(e.replace(/[^0-9]+/g, ''))}
              value={gncSuma}
              required
              success={gncSuma !== ''}
              placeholder='Ingresá GNC - Suma asegurada'
            />
            :
            <div/>
          }
          <SelectorSearcher
            label='Localidad'
            required
            id='Localidad'
            onChange={(e: string) => loadLocalidadList(e)}
            list={localidadList}
            onValueSelected={(a: any) => {
              setLocalidad(a)
              setCodigoPostal(a.codigoPostal)
              setProvincia(a.nombreProvincia)
            }}
            selectedItem={localidad && localidad?.nombre + ' (' + localidad?.codigoPostal + ')'}
            placeholder='Ingresá la localidad'
            deleteSelectedItem={() => {
              setLocalidad(null)
              setCodigoPostal('')
              setProvincia(null)
            }}
            loading={localidadListLoader}
          />
          <SelectorSearcher
            label='Provincia'
            required
            id='Provincia'
            selectedItem={provincia}
            placeholder='Seleccioná la provincia'
            disabled
          /> 
          <CustomTextInput
            label='Código postal'
            value={codigoPostal}
            required
            disabled
            placeholder='Ingresá código postal'
          />
        </div>
      </div>
      <div className="formFieldQuoteCarfacil-container-section">
        <h6 className="formFieldQuoteCarfacil-container-section-title bold">Datos del cliente</h6>
        <div className="formFieldQuoteCarfacil-container-section-inputs">
          <SelectorSearcher
            label='Tipo de persona'
            required
            id='Tipo de persona'
            list={tipoPersonaList}
            onValueSelected={(a: any) => setTipoPersona(a)}
            selectedItem={tipoPersona?.label}
            placeholder='Seleccioná el tipo de persona'
            deleteSelectedItem={() => setTipoPersona(null)}
            disabled={!tipoPersonaList}
          />
          <SelectorSearcher
            label='Responsabilidad frente al IVA'
            required
            id='Responsabilidad frente al IVA'
            list={ivaList}
            onValueSelected={(a: any) => setIva(a)}
            selectedItem={iva?.label}
            placeholder='Seleccioná el iva'
            deleteSelectedItem={() => setIva(null)}
            disabled={!ivaList || tipoPersona?.value === 2}
          />
          <div/>
          <SelectorSearcher
            label='Tipo de documento'
            required
            id='Tipo de documento'
            list={tipoDocumentoList}
            onValueSelected={(a: any) => setTipoDocumento(a)}
            selectedItem={tipoDocumento?.label}
            placeholder='Seleccioná el tipo de documento'
            deleteSelectedItem={() => setTipoDocumento(null)}
            disabled={!tipoDocumentoList || tipoPersona?.value === 2}
          />
          <CustomTextInput
            label='Documento'
            changeText={(e: string) => setNumero(e.replace(/[^0-9]+/g, ''))}
            value={numero}
            required
            success={numero !== ''}
            error={numero?.length !== 0 && !validDocument(numero, tipoDocumento?.label)}
            maxLength={isCuit(tipoDocumento?.label) ? 11 : 8}
            placeholder='Ingresá sólo números sin puntos'
          />
          <SelectorSearcher
            label='Género'
            required
            id='Género'
            list={generoList}
            onValueSelected={(a: any) => setGenero(a)}
            selectedItem={genero?.label}
            placeholder='Seleccioná el género'
            deleteSelectedItem={() => setGenero(null)}
            disabled={!generoList}
          />
        </div>
      </div>
      <div className="formFieldQuoteCarfacil-container-section">
        <h6 className="formFieldQuoteCarfacil-container-section-title bold">Alternativa comercial</h6>
        <div className="formFieldQuoteCarfacil-container-section-inputs">
          <SelectorSearcher
            label='Mercantil Andina'
            required
            id='Mercantil Andina'
            list={maBonificacionList}
            onValueSelected={(a: any) => setMaBonificacion(a)}
            selectedItem={maBonificacion?.label}
            placeholder='Seleccionar...'
            deleteSelectedItem={() => setMaBonificacion(null)}
            disabled={!maBonificacionList}
          />
          <SelectorSearcher
            label='San Cristóbal'
            required
            id='San Cristóbal'
            list={scBonificacionList}
            onValueSelected={(a: any) => setScBonificacion(a)}
            selectedItem={scBonificacion?.label}
            placeholder='Seleccionar...'
            deleteSelectedItem={() => setScBonificacion(null)}
            disabled={!scBonificacionList}
          />
        </div>
      </div>
      <div className="formFieldQuoteCarfacil-container-button">
        <MainButton
          id='COTIZAR'
          fill
          color='var(--primary500)'
          text='COTIZAR'
          disabled={isDisabled()}
          onButtonPressed={() => {
            const data = {
              "categoryId": 12,
              "questions": {
                  "30_car_query": marcaModelo,
                  "32_car_year": parseInt(año),
                  "33_car_version": version?.infoauto,
                  "47_cero_km": ceroKm,
                  "40_provincesc": provincia,
                  "34_postal_code": parseInt(codigoPostal),
                  "39_locations": localidad?.inder,
                  "35_caruse": tipoUso?.value,
                  "36_gnc": gnc?.value,
                  "37_ivaafip": iva?.value,
                  "38_genero": genero?.value,
                  "41_documentinsured": parseInt(numero),
                  "48_tipo_persona": tipoPersona?.value,
                  "49_tipo_documento": tipoDocumento?.value,
                  "50_gnc_suma": gncSuma === '' ? 0 : gncSuma,
                  "46_bonificacion": {
                    "mercantil": maBonificacion?.value,
                    "sancristobal": scBonificacion?.value
                  }
              }
            }
            props.cotizar(data)
          }}
        />
      </div>
    </div>
  );
}

export default FormFieldQuoteCarfacil;