import {
    FETCH_CATEGORIAS,
    CATEGORIA_SELECTED,
    LOADING,
    OFFER_DATA,
    RESQUEST_SUCCESS_POLICY,
    GLOBAL_FORM_ANSWER
} from './types';
import {
    getAllCategories,
    netQuoteCotizaCategoria,
    netAnswerCotizar,
    netGetQuoteQuestions,
    netDeleteQuoteQuestions,
    getOffersNet,
    netQuoteData,
    netGetVersionList,
    netAnswerOpenCotizar,
    netCreateOfferInOneStep,
    netGetLocalidadList
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const fetchCategoriaList = () => {
    return (dispatch: any) => {
        //dispatch({type: LOADING, payload: true});
        axios.get(getAllCategories())
            .then((response: any) => {
                let aux = response.data.filter((a: any) => a.menu)
                aux.sort((a:any,b:any) => {
                    if(a.order == b.order) return 0
                    if(a.order === null ) return 1;
                    if(b.order === null ) return -1;
                    return a.order - b.order;
                })
                dispatch({type: FETCH_CATEGORIAS, payload: aux});
                //dispatch({type: LOADING, payload: false});
            })
            .catch((error: any) => {
                //dispatch({type: LOADING, payload: false});
                //genericErrorHandler(error)
            });
    }
};

export const categoriaSelectedChange = (categoryId: Number) => {
    return {
        type: CATEGORIA_SELECTED, 
        payload: categoryId
    };
}

export const getQuoteData = (categoryId: Number, setQuoteData: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(netQuoteData(categoryId))
            .then((response: any) => {
                setQuoteData(response.data)
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false}); 
                genericErrorHandler(error)
            })
    };
}

export const actionCreateOfferInOneStep = (data: any, setLoadingOffer: any, navigate: any, categoryId: any, admin: any, appBaseIframe: any) => {
    return (dispatch: any) => {
        setLoadingOffer(true)
        axios({
            url: netCreateOfferInOneStep(),
            method: 'post', 
            data
        })
            .then((response) => {
                const adminN = admin ? '&admin=True' : ''
                dispatch({ type: OFFER_DATA, payload: response.data });
                navigate('/oferta?cId=' + categoryId + '&offer=' + response?.data?.id + adminN)
                setLoadingOffer(false)
            })
            .catch((error) => {
                setLoadingOffer(false)
                genericErrorHandler(error)
            })
    }
}

export const categoriaQuestion = (categoryId: Number, navigate: any, countryId: any, admin: false, setCotizarQuestion: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios({
            url: netQuoteCotizaCategoria(categoryId),
            method: 'post', 
            headers: {'Context': btoa(GetGlobalParams())}
        })
            .then((response) => {
                setCotizarQuestion(response.data);
                const adminN = admin ? '&admin=True' : ''
                navigate(GetCountryUrl(countryId) + 'cotizar?cId=' + categoryId + '&quote=' + response.data.id + adminN)
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false}); 
                genericErrorHandler(error)
            })
    };
}

export const categoriaToOffer = (productInsuredId: any) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING, payload: true });
        axios({
            url: getOffersNet(),
            method: 'post', 
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
            data: {
                productInsuredId: productInsuredId,
                productInsuredUserId: null,
            }
        })
            .then((response) => {
                dispatch({ type: OFFER_DATA, payload: response.data });
                dispatch({ type: LOADING, payload: false });
            })
            .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error);
            });
    };
}

export const getQuoteQuestions = (categoryId: Number, quote: String, setCotizarQuestion: any) => {
    axios.get(netGetQuoteQuestions(categoryId, quote))
        .then((response) => { 
            setCotizarQuestion(response.data);
        })
        .catch((error) => {
        })
}

export const answerOpenQuestion = (answer: number, categoryId: number, setLoadingOffer: any, navigate: any, admin: any, setNumeroCotizacionError: any, setNumeroCotizacionErrorText: any) => {
    setLoadingOffer(true)
    axios({
        url: netAnswerOpenCotizar(categoryId, answer),
        method: 'post', 
    })
        .then((response) => {
            const adminN = admin ? '&admin=True' : ''
            navigate('/oferta?cId=' + categoryId + '&offer=' + response?.data?.id + adminN)
            setLoadingOffer(false)
        })
        .catch((error) => {
            setLoadingOffer(false)
            setNumeroCotizacionError(true)
            setNumeroCotizacionErrorText(error?.response?.data?.message)
        })
}

export const answerQuestion = (categoryId: Number, quoteId: String, answer: any, navigate: any, countryId: any, admin: boolean, setLoadingOffer: any, setNumeroCotizacionError: any, setNumeroCotizacionErrorText: any, appBaseIframe: any, setCotizarQuestion: any, isLast: boolean) => {
    return (dispatch: any) => {
        if(isLast) {
            setLoadingOffer(true)
        } else {
            dispatch({type: LOADING, payload: true});
        }
        const data = {
            "questionId": answer.questionId,
            "value": answer.value
        }
        axios({
            url: netAnswerCotizar(categoryId, quoteId),
            method: 'post', 
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'Context': btoa(GetGlobalParams())
            },
            data
        })
            .then((responseCot) => {
                dispatch({type: GLOBAL_FORM_ANSWER, payload: {}});
                if(responseCot?.data?.next) { //Ultima Pregunta
                    axios.get(responseCot?.data?.next?.link)
                        .then((responseOff) => {
                            if (responseOff?.data?.status === "CREATING") {
                                const offerStatus = () => {
                                    axios.get(responseCot?.data?.next?.link)
                                        .then((response) => {
                                            if (response?.data?.status !== "CREATING") {
                                                dispatch({type: OFFER_DATA, payload: response?.data});
                                                const adminN = admin ? '&admin=True' : ''
                                                navigate(GetCountryUrl(countryId) + 'oferta?cId=' + categoryId + '&offer=' + responseOff?.data?.id + adminN)
                                                dispatch({type: LOADING, payload: false});
                                                setLoadingOffer(false)
                                                clearInterval(refreshIntervalId);
                                            }
                                        })
                                        .catch((error) => {
                                            genericErrorHandler(error)
                                            clearInterval(refreshIntervalId);
                                            dispatch({type: LOADING, payload: false});
                                            setLoadingOffer(false)
                                        })
                                    }
                                var refreshIntervalId = setInterval(offerStatus, 3000);
                            } else {
                                dispatch({type: LOADING, payload: false});
                                setLoadingOffer(false)
                                dispatch({type: OFFER_DATA, payload: responseOff?.data});
                                const adminN = admin ? '&admin=True' : ''
                                navigate(GetCountryUrl(countryId) + 'oferta?cId=' + categoryId + '&offer=' + responseOff?.data?.id + adminN)
                            }
                        })
                        .catch((error) => { 
                            setLoadingOffer(false)
                            dispatch({type: LOADING, payload: false});
                            genericErrorHandler(error)
                        })
                } else {
                    setCotizarQuestion(responseCot.data)
                    dispatch({type: LOADING, payload: false});
                    setLoadingOffer(false)
                }
            })
            .catch((error) => {
                setLoadingOffer(false)
                if(categoryId === 12) {
                    setNumeroCotizacionError(true)
                    setNumeroCotizacionErrorText(error?.response?.data?.message)
                }
                else genericErrorHandler(error)
                dispatch({type: LOADING, payload: false});
            })
    }
}

export const resetPolicy = (arg: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: RESQUEST_SUCCESS_POLICY, payload: arg });
    };
};

export const deleteQuestion = (categoryId: Number, quoteId: String, stepId: any, setFormAnswer: any, setCotizarQuestion: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios({
            url: netDeleteQuoteQuestions(categoryId, quoteId, stepId),
            method: 'delete', 
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => {
                setCotizarQuestion(response.data)
                setFormAnswer(null)
                dispatch({type: LOADING, payload: false});
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const actionGetVersionList = (setVersionList: any, año: any, marcaModelo: any, setVersionListLoader: any) => {
    axios.get(netGetVersionList(año, marcaModelo))
        .then((response: any) => {
            setVersionList(response?.data?.datos)
            setVersionListLoader(false)
        })
        .catch((error: any) => {
            setVersionListLoader(false)
        })
}

export const actionGetLocalidadList = (url: any, setLocalidadList: any, setLocalidadListLoader: any, setLocalidad: any, prevLocalidad: any) => {
    axios.get(url)
        .then((response: any) => {
            setLocalidadList(response?.data)
            if(setLocalidad) {
                setLocalidad(response?.data?.filter((a: any) => a.inder === parseInt(prevLocalidad))[0])
            }
            setLocalidadListLoader(false)
        })
        .catch((error: any) => {
            setLocalidadListLoader(false)
        })
}