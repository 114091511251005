import {
    USER_DATA,
    LOADING,
    CIVIL_STATUS_TYPES,
    SEX_TYPES,
    STATES,
    STATES_STRINGS,
    LOCALIDAD_EDIT_CHANGED,
    LOCALIDAD_STRING_EDIT_CHANGED,
    ADDRESS_LOCALITY_SELECTED,
    MAP_COORDINATES,
    ADDRESS_SELECTED_PROFILE,
    LOADING_ADDRESS_STREET_PROFILE,
    ADDRESS_STREET_PROFILE,
    ADDRESS_SELECTED_PLACE_ID,
    CHECKOUT_DATA,
    DIRECCION_CHANGE,
    CODIGO_POSTAL_CHANGE,
    GM_ADDRESS_FORMAT_CHANGE,
    PROVINCIA_CHANGE,
    DELEGACION_CHANGE,
    COLONIA_CHANGE,
    FETCH_CPS_BY_CITY,
    NOMBRE,
    APELLIDO,
    EMAIL,
    CELULAR,
    DNI,
    GENERO,
    FECHA_NAC,
    CUIL,
    ESTADO_CIVIL,
    PISO,
    FETCH_CITIES_BY_STATES,
    USER_PLACES,
    USER_PLACE,
    USER_PLACES_MUNICIPIOS,
    USER_PLACE_MUNICIPIO,
    USER_PLACES_LOCALIDADES,
    USER_PLACE_LOCALIDAD,
    PROFILE_ENABLE_EDIT,
    PROFILE_SHOW_MORE,
    USER_LOADER,
    USER_ACCESS_TOKEN,
    APELLIDO_MATERNO,
    DV,
    RUT,
} from './types';
import {
    getUserMine,
    getUserDataById,
    getCivilTypes,
    getSexTypes,
    getStatesCon,
    getLocByIdCon,
    editUserById,
    netCheckOutPolicyHolder,
    getLocIdCon,
    getCPsByCityCon,
    getCitiesByStatesCon,
    getPlaces,
    getUserPlacesById,
    getPlaceById,
    getBasic,
    createEditUser,
    netGetUserListForAdmin,
    getPardoData,
    getPardoLocalidades,
    getPardoCodigosPostales,
    netCarfacilAvtividades,
    netGetQuoteErrors
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import Moment from 'moment/moment'
import {store} from '../index';
import { GetGlobalParams } from '../util/GetGlobalParams';

const loadUserData = (userData: any) => {
    const {dispatch} = store;
    if(userData.name) {
        dispatch({ type: NOMBRE, payload: userData.name });
    }
    if(userData.lastName) {
        dispatch({ type: APELLIDO, payload: userData.lastName });
    }
    if(userData.username) {
        dispatch({ type: EMAIL, payload: userData.username });
    }
    if(userData.phone) {
        dispatch({ type: CELULAR, payload: userData.phone });
    }
    if(userData.motherLastName) {
        dispatch({ type: APELLIDO_MATERNO, payload: userData?.motherLastName });
    }
    if(userData.documentNumber) {
        dispatch({ type: DNI, payload: userData.documentNumber });
        dispatch({ type: RUT, payload: userData?.documentNumber?.substring(0,8) });
        dispatch({ type: DV, payload: userData?.documentNumber?.slice(userData?.documentNumber?.length - 1) });
    }
    if(userData.sexId) {
        dispatch({ type: GENERO, payload: userData.sexId });
    }
    if(userData.birthDate) {
        let dateOldFormat = Moment(userData.birthDate).format('YYYY-MM-DD')
        let dateNewFormat = Moment(dateOldFormat).format('DD/MM/YYYY')
        dispatch({ type: FECHA_NAC, payload: dateNewFormat });
    }
    if(userData.cuil) {
        dispatch({ type: CUIL, payload: userData.cuil });
    }
    if(userData.civilStatusId) {
        dispatch({ type: ESTADO_CIVIL, payload: userData.civilStatusId.toString() });
    }
    if(userData.addressStreet) {
        dispatch({ type: DIRECCION_CHANGE, payload: userData.addressStreet });
    }

    if(userData.provinciaId) {
        dispatch({ type: PROVINCIA_CHANGE, payload: userData.provinciaId });
        if(userData.countryId === 4 || userData.countryId === 5) {
            axios.get(getPlaceById(userData.provinciaId))
                .then(response1 => {
                    dispatch({type: USER_PLACE, payload: response1.data});
                    axios.get(getUserPlacesById(userData.provinciaId)).then(response2 => {
                        dispatch({type: USER_PLACES_MUNICIPIOS, payload: response2.data});
                        if(userData.colonia) {
                            axios.get(getPlaceById(parseInt(userData.colonia)))
                                .then(response3 => {
                                    dispatch({type: USER_PLACE_MUNICIPIO, payload: response3.data});
                                    axios.get(getUserPlacesById(parseInt(userData.colonia))).then(response4 => {
                                        dispatch({type: USER_PLACES_LOCALIDADES, payload: response4.data});
                                        if(userData.localidadId) {
                                            axios.get(getPlaceById(userData.localidadId))
                                                .then(response5 => {
                                                    dispatch({type: USER_PLACE_LOCALIDAD, payload: response5.data});
                                                })
                                                .catch(error => {
                                                })
                                        }
                                    })
                                    .catch(error => {
                                    })
                                })
                                .catch(error => {
                                })
                        }
                    })
                    .catch(error => {
                    })
                })
                .catch(error => {
                })

        }
    }
    if(userData.addressFloor) {
        dispatch({ type: PISO, payload: userData.addressFloor });
    }
    if(userData.addressPostalCode) {
        dispatch({ type: CODIGO_POSTAL_CHANGE, payload: userData.addressPostalCode });
    }
    if(userData.delegacion) {
        dispatch({ type: DELEGACION_CHANGE, payload: userData.delegacion });
    }
    if(userData.colonia) {
        dispatch({ type: COLONIA_CHANGE, payload: userData.colonia });
    }
}

export const resetEditData = () => {
    return (dispatch: any) => {
        dispatch({ type: NOMBRE, payload: '' });
        dispatch({ type: APELLIDO, payload: '' });
        dispatch({ type: EMAIL, payload: '' });
        dispatch({ type: CELULAR, payload: '' });
        dispatch({ type: DNI, payload: '' });
        dispatch({ type: GENERO, payload: null });
        dispatch({ type: FECHA_NAC, payload: '' });
        dispatch({ type: CUIL, payload: '' });
        dispatch({ type: ESTADO_CIVIL, payload: null });
        dispatch({ type: DIRECCION_CHANGE, payload: '' });
        dispatch({ type: PROVINCIA_CHANGE, payload: '' });
        dispatch({ type: PISO, payload: '' });
        dispatch({ type: CODIGO_POSTAL_CHANGE, payload: '' });
        dispatch({ type: DELEGACION_CHANGE, payload: '' });
        dispatch({ type: COLONIA_CHANGE, payload: '' });
    }
}

export const getDataForUserEditProfile = (userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserMine())
            .then((responseUser) => {
                const user = responseUser.data
                loadUserData(user)
                dispatch({ type: USER_DATA, payload: user });
                axios.get(getCivilTypes()).then(responseCivil => {
                    let objCivilType: any = [];
                    responseCivil.data.map((a: any) => {
                        let itemy: any = {
                            label: a.descripcion,
                            codigo: a.codigo,
                        }
                        objCivilType.push(itemy)
                    })
                    dispatch({ type: CIVIL_STATUS_TYPES, payload: objCivilType });
                    axios.get(getSexTypes()).then(responseS => {
                        let arraySexTypes: any = [];
                        responseS.data.map((a: any) => {
                            let itemt: any = {
                                label: a.descripcion,
                                codigo: a.codigo,
                            }
                            arraySexTypes.push(itemt)
                        })
                        dispatch({ type: SEX_TYPES, payload: arraySexTypes });
                        axios.get(getStatesCon()).then(responseStates => {
                            let aux: any = []
                            dispatch({ type: STATES, payload: responseStates.data });
                            responseStates.data.map((s: any) => { aux.push(s.provincia) })
                            dispatch({ type: STATES_STRINGS, payload: aux });
                            axios.get(getLocByIdCon(user.localidadId)).then(responseLoc => {
                                if(user.localidadId) {
                                    dispatch({ type: LOCALIDAD_EDIT_CHANGED, payload: user.localidadId });
                                    dispatch({ type: LOCALIDAD_STRING_EDIT_CHANGED, payload: responseLoc.data[0].localidad });
                                }
                                dispatch({type: LOADING, payload: false});
                                dispatch({ type: ADDRESS_LOCALITY_SELECTED, payload: '' });
                            }).catch(error => {
                                genericErrorHandler(error)
                                dispatch({type: LOADING, payload: false});
                            });
                        }).catch(error => {
                            dispatch({type: LOADING, payload: false});
                            genericErrorHandler(error)
                        });
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
                }).catch(error => {
                    genericErrorHandler(error)
                    dispatch({type: LOADING, payload: false});
                });
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const actionUserData = (setUserData: any) => {
    axios.get(getUserMine())
        .then((response) => {
            axios.get(getUserDataById(response?.data?.id))
                .then((responseUser) => {
                    setUserData(responseUser?.data)
                })
                .catch(() => {
                    
                })
        })
        .catch(() => {
            
        })
}

export const getDataForUserEdit = (userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserDataById(userId))
            .then((responseUser) => {
                const user = responseUser.data
                dispatch({ type: USER_DATA, payload: user });
                loadUserData(user)
                axios.get(getCivilTypes()).then(responseCivil => {
                    let objCivilType: any = [];
                    responseCivil.data.map((a: any) => {
                        let itemg: any = {
                            codigo: a.codigo,
                            label: a.descripcion
                        }
                        objCivilType.push(itemg)
                    })
                    dispatch({ type: CIVIL_STATUS_TYPES, payload: objCivilType });
                    axios.get(getSexTypes()).then(responseS => {
                        let arraySexTypes: any = [];
                        responseS.data.map((a: any) => {
                            let itemh: any = {
                                codigo: a.codigo,
                                label: a.descripcion
                            }
                            arraySexTypes.push(itemh)
                        })
                        dispatch({ type: SEX_TYPES, payload: arraySexTypes });
                        axios.get(getStatesCon()).then(responseStates => {
                            let aux: any = []
                            dispatch({ type: STATES, payload: responseStates.data });
                            responseStates.data.map((s: any) => {
                                aux.push(s.provincia)
                            })
                            dispatch({ type: STATES_STRINGS, payload: aux });
                            axios.get(getLocByIdCon(user.localidadId)).then(responseLoc => {
                                if(user.localidadId) {
                                    dispatch({ type: LOCALIDAD_EDIT_CHANGED, payload: user.localidadId });
                                    dispatch({ type: LOCALIDAD_STRING_EDIT_CHANGED, payload: responseLoc.data[0].localidad });
                                }
                                dispatch({ type: ADDRESS_LOCALITY_SELECTED, payload: '' });
                                dispatch({type: LOADING, payload: false});
                            }).catch(error => {
                                dispatch({type: LOADING, payload: false});
                                genericErrorHandler(error)
                            });
                        }).catch(error => {
                            dispatch({type: LOADING, payload: false});
                            genericErrorHandler(error)
                        });
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
                }).catch(error => {
                    dispatch({type: LOADING, payload: false});
                    genericErrorHandler(error)
                });
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const getDataForUserEditVenProfile = (userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserMine())
            .then((responseUser) => {
                const user = responseUser.data; 
                loadUserData(user) 
                dispatch({ type: USER_DATA, payload: user });
                axios.get(getCivilTypes()).then(responseCivil => {
                    let objCivilType: any = [];
                    responseCivil.data.map((a: any) => { let itemf: any = {codigo: a.codigo, label: a.descripcion}
                        objCivilType.push(itemf)
                    })
                    dispatch({ type: CIVIL_STATUS_TYPES, payload: objCivilType });
                    axios.get(getSexTypes()).then(responseS => {
                        let arraySexTypes: any = [];
                        responseS.data.map((a: any) => { let iteme: any = { codigo: a.codigo, label: a.descripcion}
                            arraySexTypes.push(iteme)
                        })
                        dispatch({ type: SEX_TYPES, payload: arraySexTypes });
                        axios.get(getPlaces()).then(responseP => {
                            dispatch({type: LOADING, payload: false});
                            dispatch({type: USER_PLACES, payload: responseP.data});
                        })
                        .catch(error => {
                            genericErrorHandler(error)
                            dispatch({type: LOADING, payload: false});
                        })
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
                }).catch(error => {
                    genericErrorHandler(error)
                    dispatch({type: LOADING, payload: false});
                });
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const getDataForUserEditVen = (userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserDataById(userId))
            .then((responseUser) => {
                const user = responseUser.data; dispatch({ type: USER_DATA, payload: user });
                loadUserData(user)
                axios.get(getCivilTypes()).then(responseCivil => {
                    let objCivilType: any = [];
                    responseCivil.data.map((a: any) => {
                        let itemc: any = {codigo: a.codigo, label: a.descripcion}
                        objCivilType.push(itemc)
                    })
                    dispatch({ type: CIVIL_STATUS_TYPES, payload: objCivilType });
                    axios.get(getSexTypes()).then(responseS => {
                        let arraySexTypes: any = [];
                        responseS.data.map((a: any) => {
                            let itemd: any = { codigo: a.codigo, label: a.descripcion}
                            arraySexTypes.push(itemd)
                        })
                        dispatch({ type: SEX_TYPES, payload: arraySexTypes });
                        axios.get(getPlaces()).then(responseP => {
                            dispatch({type: USER_PLACES, payload: responseP.data});
                            dispatch({type: LOADING, payload: false});
                        })
                        .catch(error => {
                            dispatch({type: LOADING, payload: false}); genericErrorHandler(error)
                        })
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
                }).catch(error => {
                    dispatch({type: LOADING, payload: false});
                    genericErrorHandler(error)
                });
            })
            .catch((error) => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            })
    }
}

export const getDataForNewUserArgPardo = (setTipoDocuList: any, setSexoList: any, setEstadoCivilList: any, setNacionalidadList: any, setTipoDomiList: any, setLocalidadList: any, setProvicinaList: any) => {
    return (dispatch: any) => {
        axios.get(getPardoData("tipo_documentos")).then(responseTipoDocumentos => {
            setTipoDocuList(responseTipoDocumentos.data);
            axios.get(getPardoData("sexos")).then(responseSexos => {
                setSexoList(responseSexos.data);
                axios.get(getPardoData("estado_civil")).then(responseEstadoCivil => {
                    setEstadoCivilList(responseEstadoCivil.data);
                    axios.get(getPardoData("paises")).then(responsePaises => {
                        setNacionalidadList(responsePaises.data);
                        axios.get(getPardoData("tipo_domicilio")).then(responseTipoDomicilio => {
                            setTipoDomiList(responseTipoDomicilio.data);
                            axios.get(getPardoData("provincias")).then(responseProvincias => {
                                setProvicinaList(responseProvincias.data);
                            }).catch(error => {
                                genericErrorHandler(error)
                            });
                        }).catch(error => {
                            genericErrorHandler(error)
                        });
                    }).catch(error => {
                        genericErrorHandler(error)
                    });
                }).catch(error => {
                    genericErrorHandler(error)
                });
            }).catch(error => {
                genericErrorHandler(error)
            });
        }).catch(error => {
            genericErrorHandler(error)
        });
    }
}

export const getLocalidadesByProvinciaPardo = (setLocalidadList: any, provinciaId: any) => {
    return (dispatch: any) => {
        axios.get(getPardoLocalidades(provinciaId)).then(responseLocalidades => {
            setLocalidadList(responseLocalidades.data);
        }).catch(error => {
            genericErrorHandler(error)
        });
    }
}

export const getCodigosPostalesByLocalidadPardo = (setCodigosPostalesList: any, localidadId: any) => {
    return (dispatch: any) => {
        axios.get(getPardoCodigosPostales(localidadId)).then(responseCodigosPostales => {
            setCodigosPostalesList(responseCodigosPostales.data);
        }).catch(error => {
            genericErrorHandler(error)
        });
    }
}

export const getDataForNewUserArg = (setTipoDocuList: any, setSexoList: any, setEstadoCivilList: any, setNacionalidadList: any, setTipoDomiList: any, setLocalidadList: any, setProvicinaList: any) => {
    return (dispatch: any) => {
        axios.get(getCivilTypes()).then(responseCivil => {
            let objCivilType: any = [];
            responseCivil.data.map((a: any) => {
                let item: any = { codigo: a.codigo, label: a.descripcion
                }
                objCivilType.push(item)
            })
            setEstadoCivilList(objCivilType);
            axios.get(getSexTypes()).then(responseS => {
                let arraySexTypes: any = [];
                responseS.data.map((a: any) => {
                    let item: any = { codigo: a.codigo, label: a.descripcion }
                    arraySexTypes.push(item)
                })
                setSexoList(arraySexTypes);
                axios.get(getStatesCon()).then(responseStates => {
                    let aux: any = []
                    dispatch({ type: STATES, payload: responseStates.data });
                    responseStates.data.map((s: any) => {
                        aux.push(s.provincia)
                    })
                    dispatch({ type: ADDRESS_LOCALITY_SELECTED, payload: '' }); dispatch({ type: STATES_STRINGS, payload: aux });
                    dispatch({type: LOADING, payload: false});
                }).catch(error => {
                    dispatch({type: LOADING, payload: false});
                    genericErrorHandler(error)
                });
            }).catch(error => {
                genericErrorHandler(error)
                dispatch({type: LOADING, payload: false});
            });
        }).catch(error => {
            dispatch({type: LOADING, payload: false});
            genericErrorHandler(error)
        });
    }
}

export const getDataForNewUserVen = () => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getCivilTypes()).then(responseCivil => {
            let objCivilType: any = [];
            responseCivil.data.map((a: any) => {
                let itema: any = {codigo: a.codigo, 
                    label: a.descripcion}
                objCivilType.push(itema)
            })
            dispatch({ type: CIVIL_STATUS_TYPES, payload: objCivilType });
            axios.get(getSexTypes()).then(responseS => {
                let arraySexTypes: any = [];
                responseS.data.map((a: any) => {
                    let itemb: any = { codigo: a.codigo, 
                        label: a.descripcion}
                    arraySexTypes.push(itemb)
                })
                dispatch({ type: SEX_TYPES, payload: arraySexTypes });
                axios.get(getPlaces()).then(responseP => {
                    dispatch({type: USER_PLACES, payload: responseP.data});
                    dispatch({type: LOADING, payload: false});
                })
                .catch(error => {
                    genericErrorHandler(error)
                    dispatch({type: LOADING, payload: false}); 
                })
            }).catch(error => {
                dispatch({type: LOADING, payload: false}); genericErrorHandler(error)
            });
        }).catch(error => {
            dispatch({type: LOADING, payload: false});
            genericErrorHandler(error)
        });
    }
}

export const getUserMunicipio = (id: number) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserPlacesById(id)).then(response => {
            dispatch({type: USER_PLACES_MUNICIPIOS, payload: response.data});
            dispatch({type: LOADING, payload: false});
        })
        .catch(error => {
            dispatch({type: LOADING, payload: false});
            genericErrorHandler(error)
        })
    }
}

export const getUserLocalidad = (id: number) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserPlacesById(id)).then(response => {
            dispatch({type: USER_PLACES_LOCALIDADES, payload: response.data});
            dispatch({type: LOADING, payload: false});
        })
        .catch(error => {
            dispatch({type: LOADING, payload: false});
            genericErrorHandler(error)
        })
    }
}

export const coordinateMarkerMapChanged = (lat: any, lng: any) => {
    return (dispatch: any) => {
        let aux = {center: {lat: lat, lng: lng}, zoom: 11}
        dispatch({ type: MAP_COORDINATES, payload: aux });
    }
}

export const setEnableEdit = (value: boolean) => {
    return {
        type: PROFILE_ENABLE_EDIT,
        payload: value
    };
}

export const setShowMore = (value: boolean) => {
    return {
        type: PROFILE_SHOW_MORE,
        payload: value
    };
}

export const emptyProfileSelected = () => {
    return {
        type: ADDRESS_SELECTED_PROFILE,
        payload: {}
    };
}

export const setUserPlace = (e: any) => {
    return {
        type: USER_PLACE,
        payload: e
    };
}

export const setUserPlaceMunicipio = (e: any) => {
    return {
        type: USER_PLACE_MUNICIPIO,
        payload: e
    };
}

export const setUserPlaceLocalidad = (e: any) => {
    return {
        type: USER_PLACE_LOCALIDAD,
        payload: e
    };
}

export const loadingAddressStreetProfileChanged = (value: boolean) => {
    return (dispatch: any) => {
        dispatch({ type: LOADING_ADDRESS_STREET_PROFILE, payload: value });
    };
};

export const addressStreetProfileChanged = (text) => {
    return {
        type: ADDRESS_STREET_PROFILE,
        payload: text
    };
};

export const emptyAddressPlaceId = (text) => {
    return {
        type: ADDRESS_SELECTED_PLACE_ID,
        payload: text
    };
}

export const nombreChanged = (text: string) => {
    return {
        type: NOMBRE,
        payload: text
    };
}

export const apellidoChanged = (text: string) => {
    return {
        type: APELLIDO,
        payload: text
    };
}

export const emailChanged = (text: string) => {
    return {
        type: EMAIL,
        payload: text
    };
}

export const celularChanged = (text: string) => {
    return {
        type: CELULAR,
        payload: text
    };
}

export const dniChanged = (text: string) => {
    return {
        type: DNI,
        payload: text
    };
}

export const generoChanged = (text: string) => {
    return {
        type: GENERO,
        payload: text
    };
}

export const fechaNacChanged = (text: string) => {
    return {
        type: FECHA_NAC,
        payload: text
    };
}

export const cuilChanged = (text: string) => {
    return {
        type: CUIL,
        payload: text
    };
}

export const estadoCivilChanged = (text: string) => {
    return {
        type: ESTADO_CIVIL,
        payload: text
    };
}

export const pisoChanged = (text: string) => {
    return {
        type: PISO,
        payload: text
    };
}

export const editUserProfile2 = ({ name, lastName, dni, localidad, addressStreet, addressFloor, postalCode, objCivilType, civilStatus, sex, phone, cuil, addressFormatGM, userName }, dateGoodFormat: string, provCode: string, checkOutId: string, userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        let sexId = sex;
        let civilStatusId = objCivilType.filter((a: any) => a.codigo === civilStatus)[0].codigo;
        const data = {
            'name': name,
            'lastName': lastName,
            'documentNumber': dni,
            'addressStreet': addressStreet,
            'addressFloor': addressFloor,
            'addressPostalCode': postalCode,
            'provincia': provCode,
            'localidad': localidad,
            'birthDate': dateGoodFormat,
            'phone': phone,
            'cuil': cuil,
            'sexId': sexId,
            'civilStatusId': civilStatusId,
            'addressFormatGM': addressFormatGM,
            'userName': userName
        }
        axios.patch(editUserById(userId), data)
            .then(responseUser => {
                let user = responseUser.data;
                dispatch({type: USER_DATA, payload: user});
                if (checkOutId !== '-1') {
                    axios({
                        url: netCheckOutPolicyHolder(checkOutId, user.id),
                        method: 'put',
                        headers: {
                            'accept': '*/*',
                            'Content-Type': 'application/json',
                        }
                    }).then(responseCheck => {
                        dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                        dispatch({type: LOADING, payload: false});
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
                } else {
                    dispatch({type: LOADING, payload: false});
                    dispatch({type: PROFILE_ENABLE_EDIT, payload: false});
                    dispatch({type: PROFILE_SHOW_MORE, payload: false});
                }
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    };
};

export const loadDataForAdminSelectedUserArgPardo = (
    setNombre: any, setApellido: any, setFechaNac: any,
    setTipoDocu: any, setNumDocu: any, setCuit: any,
    setSexo: any, setEstadoCivil: any, setNacionalidad: any,
    setCelular: any, setEmail: any,
    setTipoDomi: any, setCalle: any, setNro: any, setPiso: any, setDpto: any,
    setProvincia: any, setLocalidad: any, setCodigoPostal: any,
    setLocalidadList: any, setCodigosPostalesList: any, setAuthUserId: any, userData: any
) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        const user = userData
        if(user.name) setNombre(user.name);
        if(user.lastName) setApellido(user.lastName);
        if(user.birthDate) setFechaNac(Moment(user.birthDate).format('DD/MM/YYYY'));
        if(user.documentId) setTipoDocu(user.documentId);
        if(user.documentNumber) setNumDocu(user.documentNumber);
        if(user.cuil) setCuit(user.cuil);
        if(user.sexId) setSexo(user.sexId);
        if(user.civilStatus) setEstadoCivil(user.civilStatus); 
        if(user.nacionalityId) setNacionalidad(user.nacionalityId);
        if(user.phone) setCelular(user.phone);
        if(user.username) setEmail(user.username);
        if(user.addressType) setTipoDomi(user.addressType);
        if(user.addressStreet) setCalle(user.addressStreet?.slice(0, user.addressStreet?.search(/\d/) - 1));
        if(user.addressStreet) setNro(user.addressStreet?.slice(user.addressStreet?.search(/\d/)));
        if(user.addressFloor) setPiso(user.addressFloor);
        if(user.dpto) setDpto(user.dpto);
        if(user.id) setAuthUserId(user.id)
        if(user.provinciaId) {
            setProvincia(user.provinciaId);
            axios.get(getPardoLocalidades(user.provinciaId)).then(responseLocalidades => {
                if(user.localidadId) {
                    setLocalidad(user.localidadId);
                    setLocalidadList(responseLocalidades.data);
                    axios.get(getPardoCodigosPostales(user.localidadId)).then(responseCodigosPostales => {
                        if(user.addressPostalCode) {
                            setCodigoPostal(user.addressPostalCode);
                            setCodigosPostalesList(responseCodigosPostales.data);
                            dispatch({type: LOADING, payload: false});
                        }
                    }).catch(error => {
                        genericErrorHandler(error)
                        dispatch({type: LOADING, payload: false});
                    });
                }
            }).catch(error => {
                genericErrorHandler(error)
                dispatch({type: LOADING, payload: false});
            });
        }
        dispatch({type: LOADING, payload: false});
    }
}

export const loadDataForLoggedUserArgPardo = (
    setNombre: any, setApellido: any, setFechaNac: any,
    setTipoDocu: any, setNumDocu: any, setCuit: any,
    setSexo: any, setEstadoCivil: any, setNacionalidad: any,
    setCelular: any, setEmail: any,
    setTipoDomi: any, setCalle: any, setNro: any, setPiso: any, setDpto: any,
    setProvincia: any, setLocalidad: any, setCodigoPostal: any,
    setLocalidadList: any, setCodigosPostalesList: any, setAuthUserId: any
) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        axios.get(getUserMine())
        .then((response) => {
            const user = response.data
            dispatch({type: USER_DATA, payload: user});
            setNombre(user.name);
            setApellido(user.lastName);
            setFechaNac(Moment(user.birthDate).format('DD/MM/YYYY'));
            setTipoDocu(user.documentId);
            setNumDocu(user.documentNumber);
            setCuit(user.cuil);
            setSexo(user.sexId);
            setEstadoCivil(user.civilStatus); 
            setNacionalidad(user.nacionalityId);
            setCelular(user.phone);
            setEmail(user.username);
            setTipoDomi(user.addressType);
            setCalle(user.addressStreet.slice(0, user.addressStreet.search(/\d/) - 1));
            setNro(user.addressStreet.slice(user.addressStreet.search(/\d/)));
            setPiso(user.addressFloor);
            setDpto(user.dpto);
            setProvincia(user.provinciaId);
            setAuthUserId(user.id)
            axios.get(getPardoLocalidades(user.provinciaId)).then(responseLocalidades => {
                setLocalidad(user.localidadId);
                setLocalidadList(responseLocalidades.data);
                axios.get(getPardoCodigosPostales(user.localidadId)).then(responseCodigosPostales => {
                    setCodigoPostal(user.addressPostalCode);
                    setCodigosPostalesList(responseCodigosPostales.data);
                    dispatch({type: LOADING, payload: false});
                }).catch(error => {
                    genericErrorHandler(error)
                    dispatch({type: LOADING, payload: false});
                });
            }).catch(error => {
                genericErrorHandler(error)
                dispatch({type: LOADING, payload: false});
            });
        })
        .catch(error => {
            dispatch({type: LOADING, payload: false});
            genericErrorHandler(error)
        });
    }
}

export const editUserProfileArgPardo = (
    nombre: any, apellido: any, dateGoodFormat: any,
    tipoDocu: any, numDocu: any, cuit: any,
    sexo: any, estadoCivil: any, nacionalidad: any,
    celular: any, email: any,
    tipoDomi: any, calle: any, nro: any, piso: any, dpto: any,
    localidad: any, localidadText: any, codigoPostal: any, provincia: any, provinciaText: any,
    checkOutDataId: any, userId: any, admin: boolean, appBaseIframe: any
) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        const fastUserData = {
            'name': nombre,
            'lastName': apellido,
            'birthDate': dateGoodFormat,

            'documentId': tipoDocu,
            'documentNumber': numDocu,
            'cuil': cuit,

            'sexId': sexo,
            'civilStatus': estadoCivil,
            'nacionalityId': nacionalidad,
            
            'phone': celular,
            'userName': email,

            'addressType': tipoDomi,
            'addressStreet': calle + ' ' + nro,
            'addressFloor': piso,
            'dpto': dpto,
            
            'provincia': parseInt(provincia, 10),
            'provinciaText': provinciaText,
            'localidad': parseInt(localidad, 10),
            'localidadText': localidadText,
            'addressPostalCode': codigoPostal,
        }
        axios.patch(editUserById(userId), fastUserData)
            .then(responseFas => {
                axios({url: netCheckOutPolicyHolder(checkOutDataId, userId),
                    method: 'put', headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json',
                    }
                }).then(responseCheck => {
                    dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                    dispatch({type: LOADING, payload: false});
                }).catch(error => {
                    dispatch({type: LOADING, payload: false});
                    genericErrorHandler(error);
                });
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    }    
}

export const createUserProfileArgPardo = (
    nombre: any, apellido: any, dateGoodFormat: any,
    tipoDocu: any, numDocu: any, cuit: any,
    sexo: any, estadoCivil: any, nacionalidad: any,
    celular: any, email: any,
    tipoDomi: any, calle: any, nro: any, piso: any, dpto: any,
    localidad: any, localidadText: any, codigoPostal: any, provincia: any, provinciaText: any,
    checkOutDataId: any, admin: boolean,
    appBaseIframe: any
) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        const fastUserData = {
            'name': nombre,
            'lastName': apellido,
            'birthDate': dateGoodFormat,

            'documentId': tipoDocu,
            'documentNumber': numDocu,
            'cuil': cuit,

            'sexId': sexo,
            'civilStatus': estadoCivil,
            'nacionalityId': nacionalidad,
            
            'phone': celular,
            'userName': email,

            'addressType': tipoDomi,
            'addressStreet': calle + ' ' + nro,
            'addressFloor': piso,
            'dpto': dpto,
            
            'provincia': parseInt(provincia, 10),
            'provinciaText': provinciaText,
            'localidad': parseInt(localidad, 10),
            'localidadText': localidadText,
            'addressPostalCode': codigoPostal,
        }
        axios({
            method: 'post',
            url: createEditUser(-1),
            headers: {
                Authorization: getBasic(),
                'Content-Type': 'application/json'
            },
            data: fastUserData
        })
            .then(responseFas => {
                const userId = responseFas.data.id
                dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseFas.data.accesToken })
                axios.get(getUserMine())
                    .then((response) => {
                        const user = response.data
                        dispatch({type: USER_DATA, payload: user});
                        if (admin) {
                            dispatch({ type: USER_ACCESS_TOKEN, payload: null })
                            if(window.location.hostname != 'localhost') {
                                window.parent.postMessage('Give', appBaseIframe)
                                setTimeout(() => {
                                }, 5000)
                            }
                        }

                        axios({url: netCheckOutPolicyHolder(checkOutDataId, userId),
                            method: 'put', headers: {
                                'accept': '*/*',
                                'Content-Type': 'application/json',
                            }
                        }).then(responseCheck => {
                            dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                            dispatch({type: LOADING, payload: false});
                        }).catch(error => {
                            dispatch({type: LOADING, payload: false});
                            genericErrorHandler(error);
                        });
                    })
                    .catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    }    
}

export const createUserCheckout = (fastUserData: any, checkOutDataId: any, notEditable: any, preExistingUserId: any, currentPackage: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        if(preExistingUserId) {
            axios.patch(editUserById(preExistingUserId), fastUserData)
                .then(responseEdit => {
                    axios({url: netCheckOutPolicyHolder(checkOutDataId, preExistingUserId),
                        method: 'put', headers: {
                            'accept': '*/*',
                            'Content-Type': 'application/json',
                        }
                    }).then(responseCheck => {
                        dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                        dispatch({type: LOADING, payload: false});
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error);
                    });
                })
                .catch(error => {
                    dispatch({type: LOADING, payload: false});
                    genericErrorHandler(error)
                });
        } else {
            axios({
                method: 'post',
                url: createEditUser(currentPackage?.insurer?.id),
                headers: {
                    Authorization: getBasic(),
                    'Content-Type': 'application/json'
                },
                data: fastUserData
            })
                .then(responseFas => {
                    const userId = responseFas.data.id
                    axios({url: netCheckOutPolicyHolder(checkOutDataId, userId),
                        method: 'put', headers: {
                            'accept': '*/*',
                            'Content-Type': 'application/json',
                        }
                    }).then(responseCheck => {
                        dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                        dispatch({type: LOADING, payload: false});
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error);
                    });
                })
                .catch(error => {
                    dispatch({type: LOADING, payload: false});
                    genericErrorHandler(error)
                });
        }
    }    
}

export const createUserProfileArgPass = (name, lastName, dni, addressStreet, addressFloor, localidad, postalCode, objCivilType, civilStatus, sex, phone, cuil, userName, dateGoodFormat, provCode, checkOutId, contraseñaRegister) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        let sexId = sex;
        let civilStatusId = objCivilType.filter((a: any) => a.codigo === civilStatus)[0].codigo;
        const fastUserData = {
            'name': name,
            'lastName': lastName,
            'documentNumber': dni,
            'addressStreet': addressStreet,
            'addressFloor': addressFloor,
            'addressPostalCode': postalCode,
            'provincia': provCode,
            'localidad': localidad,
            'birthDate': dateGoodFormat,
            "password": contraseñaRegister,
            'phone': phone,
            'cuil': cuil,
            'sexId': sexId,
            'civilStatusId': civilStatusId,
            'userName': userName
        }
        axios({
            method: 'post',
            url: createEditUser(-1),
            headers: {
                Authorization: getBasic(),
                'Content-Type': 'application/json',
                'Context': btoa(GetGlobalParams())
            },
            data: fastUserData
        })
            .then(responseFas => {
                const userId = responseFas.data.id
                dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseFas.data.accesToken })
                axios({
                    method: 'get',
                    url: getUserMine()
                })
                    .then((response) => {
                        const user = response.data
                        dispatch({type: USER_DATA, payload: user});
                        if (checkOutId !== '-1') {
                            axios({url: netCheckOutPolicyHolder(checkOutId, userId),
                                method: 'put', headers: {
                                    'accept': '*/*',
                                    'Content-Type': 'application/json',
                                }
                            }).then(responseCheck => {
                                dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                                dispatch({type: LOADING, payload: false});
                            }).catch(error => {
                                dispatch({type: LOADING, payload: false});
                                genericErrorHandler(error);
                            });
                        } else {
                            dispatch({type: LOADING, payload: false});
                            dispatch({type: PROFILE_ENABLE_EDIT, payload: false});
                            dispatch({type: PROFILE_SHOW_MORE, payload: false});
                        }
                    })
                    .catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    }    
}

export const createUserProfileVen = (name: string, lastName: string, dni: string, addressStreet: string, addressFloor: string, estado: any, municipio: any, localidad: any, objCivilType: any, civilStatus: any, sex: any, phone: string, userName: string, dateGoodFormat: string, checkOutId: string, contraseñaRegister: string, setAT: boolean) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        let sexId = sex;
        let civilStatusId = objCivilType.filter((a: any) => a.codigo === civilStatus)[0].codigo;
        const fastUserData = {
            "addressFloor": addressFloor,
            "addressStreet": addressStreet,
            "birthDate": dateGoodFormat,
            "civilStatusId": civilStatusId,
            "colonia": municipio.toString(),
            "documentNumber": dni,
            "lastName": lastName,
            "localidad": localidad,
            "name": name,
            "password": contraseñaRegister,
            "phone": phone,
            "provincia": estado,
            "sexId": sexId,
            "userName": userName
        }
        axios({
            method: 'post',
            url: createEditUser(-1),
            headers: {
                Authorization: getBasic(),
                'Content-Type': 'application/json',
                'Context': btoa(GetGlobalParams())
            },
            data: fastUserData
        })
            .then(responseFas => {
                const userId = responseFas.data.id
                if (setAT) dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseFas.data.accesToken })
                axios.get(getUserMine())
                    .then((response) => {
                        const user = response.data
                        dispatch({type: USER_DATA, payload: user});
                        if (checkOutId !== '-1') {
                            axios({url: netCheckOutPolicyHolder(checkOutId, userId),
                                method: 'put', headers: {
                                    'accept': '*/*',
                                    'Content-Type': 'application/json',
                                }
                            }).then(responseCheck => {
                                dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                                dispatch({type: LOADING, payload: false});
                            }).catch(error => {
                                dispatch({type: LOADING, payload: false});
                                genericErrorHandler(error);
                            });
                        } else {
                            dispatch({type: LOADING, payload: false});
                            dispatch({type: PROFILE_ENABLE_EDIT, payload: false});
                            dispatch({type: PROFILE_SHOW_MORE, payload: false});
                        }
                    })
                    .catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    }    
}

export const editUserProfileVen = (name: string, lastName: string, dni: string, addressStreet: string, addressFloor: string, estado: any, municipio: any, localidad: any, objCivilType: any, civilStatus: any, sex: any, phone: string, userName: string, dateGoodFormat: string, checkOutId: string, userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        let sexId = sex;
        let civilStatusId = objCivilType.filter((a: any) => a.codigo === civilStatus)[0].codigo;
        const data = {
            'name': name,
            'lastName': lastName,
            'documentNumber': dni,
            'birthDate': dateGoodFormat,
            'phone': phone,
            'sexId': sexId,
            'userName': userName,
            'civilStatusId': civilStatusId,
            'addressStreet': addressStreet,
            'addressFloor': addressFloor,
            'provincia': estado,
            'colonia': municipio.toString(),
            'localidad': localidad,
        }
        axios.patch(editUserById(userId), data)
            .then(responseUser => {
                let user = responseUser.data
                dispatch({type: USER_DATA, payload: user});
                if (checkOutId !== '-1') {
                    axios({url: netCheckOutPolicyHolder(checkOutId, user.id),
                        method: 'put', headers: {
                            'accept': '*/*',
                            'Content-Type': 'application/json',
                        }
                    }).then(responseCheck => {
                        dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});dispatch({type: LOADING, payload: false});
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});genericErrorHandler(error);
                    });
                } else {
                    dispatch({type: LOADING, payload: false});dispatch({type: PROFILE_ENABLE_EDIT, payload: false});
                    dispatch({type: PROFILE_SHOW_MORE, payload: false});
                }
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    };
};

export const createUserProfileSS = (name: string, apellidoMaterno: string, apellidoPaterno: string, dni: string, addressStreet: string, addressFloor: string, estado: any, objCivilType: any, civilStatus: any, sex: any, phone: string, userName: string, dateGoodFormat: string, checkOutId: string, contraseñaRegister: string, setAT: boolean) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        let sexId = sex;
        let civilStatusId = objCivilType.filter((a: any) => a.codigo === civilStatus)[0].codigo;
        const fastUserData = {
            "addressFloor": addressFloor,
            "addressStreet": addressStreet,
            "birthDate": dateGoodFormat,
            "civilStatusId": civilStatusId,
            "documentNumber": dni,
            'motherLastName': apellidoMaterno,
            'lastName': apellidoPaterno,
            "name": name,
            "password": contraseñaRegister,
            "phone": phone,
            "provincia": estado,
            "sexId": sexId,
            "userName": userName
        }
        axios({
            method: 'post',
            url: createEditUser(-1),
            headers: {
                Authorization: getBasic(),
                'Content-Type': 'application/json',
                'Context': btoa(GetGlobalParams())
            },
            data: fastUserData
        })
            .then(responseFas => {
                const userId = responseFas.data.id
                if (setAT) dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseFas.data.accesToken })
                axios.get(getUserMine())
                    .then((response) => {
                        const user = response.data
                        dispatch({type: USER_DATA, payload: user});
                        if (checkOutId !== '-1') {
                            axios({url: netCheckOutPolicyHolder(checkOutId, userId),
                                method: 'put', headers: {
                                    'accept': '*/*',
                                    'Content-Type': 'application/json',
                                }
                            }).then(responseCheck => {
                                dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});
                                dispatch({type: LOADING, payload: false});
                            }).catch(error => {
                                dispatch({type: LOADING, payload: false});
                                genericErrorHandler(error);
                            });
                        } else {
                            dispatch({type: LOADING, payload: false});
                            dispatch({type: PROFILE_ENABLE_EDIT, payload: false});
                            dispatch({type: PROFILE_SHOW_MORE, payload: false});
                        }
                    })
                    .catch(error => {
                        dispatch({type: LOADING, payload: false});
                        genericErrorHandler(error)
                    });
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    }    
}

export const editUserProfileSS = (name: string, apellidoMaterno: string, apellidoPaterno: string, dni: string, addressStreet: string, addressFloor: string, estado: any, objCivilType: any, civilStatus: any, sex: any, phone: string, userName: string, dateGoodFormat: string, checkOutId: string, userId: any) => {
    return (dispatch: any) => {
        dispatch({type: LOADING, payload: true});
        let sexId = sex;
        let civilStatusId = objCivilType.filter((a: any) => a.codigo === civilStatus)[0].codigo;
        const data = {
            "addressFloor": addressFloor,
            "addressStreet": addressStreet,
            "birthDate": dateGoodFormat,
            "civilStatusId": civilStatusId,
            "documentNumber": dni,
            'motherLastName': apellidoMaterno,
            'lastName': apellidoPaterno,
            "name": name,
            "phone": phone,
            "provincia": estado,
            "sexId": sexId,
            "userName": userName
        }
        axios.patch(editUserById(userId), data)
            .then(responseUser => {
                let user = responseUser.data
                dispatch({type: USER_DATA, payload: user});
                if (checkOutId !== '-1') {
                    axios({url: netCheckOutPolicyHolder(checkOutId, user.id),
                        method: 'put', headers: {
                            'accept': '*/*',
                            'Content-Type': 'application/json',
                        }
                    }).then(responseCheck => {
                        dispatch({type: CHECKOUT_DATA, payload: responseCheck.data});dispatch({type: LOADING, payload: false});
                    }).catch(error => {
                        dispatch({type: LOADING, payload: false});genericErrorHandler(error);
                    });
                } else {
                    dispatch({type: LOADING, payload: false});dispatch({type: PROFILE_ENABLE_EDIT, payload: false});
                    dispatch({type: PROFILE_SHOW_MORE, payload: false});
                }
            })
            .catch(error => {
                dispatch({type: LOADING, payload: false});
                genericErrorHandler(error)
            });
    };
};

export const getUserAuth = () => {
    return  async (dispatch: any) => {
        //dispatch({type: LOADING, payload: true});
        try {
            const response = await axios.get(getUserMine());
            const user = response.data;
            dispatch({type: USER_DATA, payload: user});
            dispatch({type: USER_LOADER, payload: true});
        } catch (error) {
            dispatch({type: USER_LOADER, payload: false});
            genericErrorHandler(error)
        } finally {
            //dispatch({type: LOADING, payload: false});
        }
    }
}

export const actionGetQuoteErrors = (setQuoteErrors: any, quoteId: any) => {
    axios.get(netGetQuoteErrors(quoteId)).then(response => {
        setQuoteErrors(response.data)
    }).catch(error => {
    });
}

export const direccionChange = (text: string) => {
    return {
        type: DIRECCION_CHANGE,
        payload: text
    };
};

export const rutChange = (text: string) => {
    return {
        type: RUT,
        payload: text
    };
};

export const dvChange = (text: string) => {
    return {
        type: DV,
        payload: text
    };
};

export const apellidoMaternoChange = (text: string) => {
    return {
        type: APELLIDO_MATERNO,
        payload: text
    };
};


export const codigoPostalChange = (text: string) => {
    return {
        type: CODIGO_POSTAL_CHANGE,
        payload: text
    };
};

export const GMAddressFormatChange = (text: string) => {
    return {
        type: GM_ADDRESS_FORMAT_CHANGE,
        payload: text
    };
};

export const provinciaChange = (text: string) => {
    return {
        type: PROVINCIA_CHANGE,
        payload: text
    };
};

export const delegacionChange = (text: string) => {
    return {
        type: DELEGACION_CHANGE,
        payload: text
    };
};

export const coloniaChange = (text: string) => {
    return {
        type: COLONIA_CHANGE,
        payload: text
    };
};

export const getCPsByCity = (access_token, city) => {
    return (dispatch: any) => {
        axios.get(getCPsByCityCon(city), { headers: { Authorization: access_token} }).then(response => {
            let res: any = []
            response.data.map((r) => {
                res.push(r.codigo.toString())
            })
            dispatch({ type: FETCH_CPS_BY_CITY, payload: res });
        }).catch(error => {
            genericErrorHandler(error)
        });
    };
};

export const getLocId = (state, city, access_token) => {
    return (dispatch: any) => {
        axios.get(getLocIdCon(state, city), { headers: { Authorization: access_token} }).then(response => {
            if(response.data.length > 0) {
                let locId = response.data[0].localidadId
                dispatch({ type: LOCALIDAD_EDIT_CHANGED, payload: locId });
                axios.get(getLocByIdCon(locId), { headers: { Authorization: access_token} }).then(responseL => {
                    let locS = responseL.data[0].localidad;
                    dispatch({ type: LOCALIDAD_STRING_EDIT_CHANGED, payload: locS });
                }).catch(error => {
                    ;
                });
            } else {
                dispatch({ type: LOCALIDAD_EDIT_CHANGED, payload: -1 });
                dispatch({ type: LOCALIDAD_STRING_EDIT_CHANGED, payload: '' });
            }
        }).catch(error => {
            genericErrorHandler(error)
        });
    };
};

export const setCABALoc = () => {
    return (dispatch: any) => {
        dispatch({ type: LOCALIDAD_EDIT_CHANGED, payload: 84506 });
        dispatch({ type: LOCALIDAD_STRING_EDIT_CHANGED, payload: 'CABA' });
    }
}

export const getCitiesByStates = (state: any, localidad: any) => {
    return (dispatch: any) => {
        if(localidad.length >= 1) {
            axios.get(getCitiesByStatesCon(state, localidad)).then(response => {
                dispatch({ type: FETCH_CITIES_BY_STATES, payload: response.data });
                dispatch({ type: LOADING_ADDRESS_STREET_PROFILE, payload: false });
            }).catch(error => {
                dispatch({ type: LOADING_ADDRESS_STREET_PROFILE, payload: false });
            });
        } else {
            dispatch({ type: FETCH_CITIES_BY_STATES, payload: [] });
            dispatch({ type: LOADING_ADDRESS_STREET_PROFILE, payload: false });
        }
    };
};

export const emptyAddressArray = () => {
    return {
        type: FETCH_CITIES_BY_STATES,
        payload: []
    }
}

export const localidadSelectedEditChanged = (id: number, text: string) => {
    return (dispatch: any) => {
        dispatch({ type: LOCALIDAD_EDIT_CHANGED, payload: id });
        dispatch({ type: LOCALIDAD_STRING_EDIT_CHANGED, payload: text });
    }
}

export const actionGetUserListForAdmin = (text: string, setLoading: any, setUserList: any) => {
    axios({
        method: 'get',
        url: netGetUserListForAdmin(),
        params: {
            "filter": text,
            "page": 1,
            "size": 30
        }
      })
        .then(response => {
            setUserList(response.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            genericErrorHandler(error)
        });
}

export const actionGetUserData = (setUserData: any, setActividadList: any, setSexoList: any, setEstadoCivilList: any) => {
    axios.get(getUserMine())
        .then((response) => {
            axios.get(getUserDataById(response?.data?.id))
                .then((response) => {
                    setUserData(response?.data)
                    axios.get(netCarfacilAvtividades(40))
                        .then((response) => {
                            setActividadList(response?.data)
                            axios.get(getSexTypes()).then(responseSexos => {
                                setSexoList(responseSexos.data);
                                axios.get(getCivilTypes()).then(responseEstadoCivil => {
                                    setEstadoCivilList(responseEstadoCivil.data);
                                })
                                .catch((error) => {

                                })
                            })
                            .catch((error) => {

                            })
                        })
                        .catch((error) => {

                        })
                })
                .catch((error) => {
            
                })
        })
        .catch((error) => {
            
        })
}