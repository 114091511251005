

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import { useMediaQuery } from 'react-responsive'
import './MisReclamos.css'
import {
  fetchDataForHome
} from '../../actions'
import Modal from 'react-modal';
import HomeSeguro from '../../components/homeComponents/HomeSeguro/HomeSeguro';
import SiniestroAlert from '../../components/alertComponents/SiniestroAlert/SiniestroAlert';

let isTabletOrMobile = false;
const MisReclamos = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
  const [listToClaim, setListToClaim] = useState<any>(null)
  const [checkoutDataList, setCheckoutDataList] = useState<any>([]);
  const [siniestroAlert, setSiniestroAlert] = useState<boolean>(false);
  const [policySelectedData, setPolicySelectedData] = useState<any>(null);
  const [pendienteList, setPendienteList] = useState<any>(null);
  const [vigenteList, setVigenteList] = useState<any>(null);
  const [noVigenteList, setNoVigenteList] = useState<any>(null);

  useEffect(() => {
    props.fetchDataForHome(setPendienteList, setVigenteList, setNoVigenteList)
  }, [])

  useEffect(() => {
    if(props.userProductsActive) {
      const aux = [...props.userProductsActive].filter(a => !a.claimStatus)
      setListToClaim(aux)
    }
  }, [props.userProductsActive])

  const reclamoAlertModal = () => {
    return (
        <Modal 
            isOpen={props.claimTypes}
            onRequestClose={() => props.toggleClaimTypes()}
            style={{
              content: {
                top: '50%', 
                left: '50%', 
                right: 'auto', 
                bottom: 'auto', 
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)', 
                borderRadius: '20px', 
                width: isTabletOrMobile ? '80%' : '30%', 
                padding: isTabletOrMobile ? '10px' : '20px',
              }, 
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
        >

        </Modal>
    )
  }

  const siniestroModal = () => {
    return (
      <Modal
        isOpen={siniestroAlert}
        onRequestClose={() => setSiniestroAlert(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: isTabletOrMobile ? '80%' : '30%',
            padding: isTabletOrMobile ? '10px' : '20px',
          },
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.5)'
          }
        }}
      >
        <SiniestroAlert
          insurer={{ img: policySelectedData?.insurer?.image, name: policySelectedData?.insurer?.name }}
          close={() => setSiniestroAlert(false)}
        />
      </Modal>
    )
  }

  return (
    <div className="misReclamos-container">
      <Header home/>
      <div className="misReclamos-container-main">
        <h4 className="misReclamos-container-main-title bold">Mis siniestros</h4>
        {listToClaim?.length > 0 ?
          <div className="misReclamos-container-main-list">
            {listToClaim?.map((a: any) => (
              <HomeSeguro 
                key={a.id}
                data={a}
                reclamar={(data: any) => {
                  setSiniestroAlert(true)
                  setPolicySelectedData(data)
                }}
                desactivar={(id: number, reDeactivate: boolean) => {}}
                setCheckoutDataList={setCheckoutDataList}
                checkoutDataList={checkoutDataList}
                activarPoliza={() => {}}
              />
            ))}
          </div>
          :
          <div className="misReclamos-container-main-emptyList">
            <span className="material-symbols-outlined" style={{ color: 'var(--gray200)', fontSize: 64 }}>gpp_bad</span>
            <p className="misReclamos-container-main-emptyList-text regular">Actualmente no tenés coberturas para denunciar siniestros.</p>
          </div>
          }
      </div>
      {siniestroModal()}
      {reclamoAlertModal()}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userProductsActive: state.home.userProductsActive,
    claimTypes: state.reclamo.claimTypes,
  };
};

export default connect(mapStateToProps, {
  fetchDataForHome
})(MisReclamos);