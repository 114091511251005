import React, {useState, useRef, useEffect} from 'react' 
import './SelectorSearcher.css'

const SelectorSearcher = (props: any) => {

  const [openList, setOpenList] = useState<Boolean>(false)
  const [completeList, setCompleteList] = useState<any>(props.list)
  const [textSearch, setTextSearch] = useState<any>('')
  const [preSelectedItemIndex, setPreSelectedItemIndex] = useState<number>(-1)

  const mainRef: any = useRef()

  useEffect(() => {
    function handleClickOutside(event: any) {
        if (mainRef.current && !mainRef.current.contains(event.target)) {
          setOpenList(false)
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef]);

  useEffect(() => {
    if(props.focus) {
      setOpenList(true)
      filterByText('')
    }
  }, [props.focus])

  const handleItemSelected = (a: any) => {
    setOpenList(false)
    props.onValueSelected(a)
    setPreSelectedItemIndex(-1)
  }

  useEffect(() => {
    if(props.list) filterByText(textSearch)
  }, [props.list, textSearch])

  const filterByText = (e: String) => {
    setTextSearch(e)
    let auxShow = props.list?.filter((a: any) => (a.nombre ? a.nombre : props.cp ? a.descripcion + ' (' + a.codigoPostal + ')' : a.label ? a.label : a.descripcion ? a.descripcion : a.name)?.toLowerCase()?.includes(e?.toLowerCase()))
    setCompleteList(auxShow)
  }

  return (
    <div ref={mainRef} className="selectorSearcher-container">
      {props.label &&
        <div className="selectorSearcher-container-label">
          <p className="selectorSearcher-container-label-title bold" style={{ color: props.adminLogin ? 'var(--gray100)' : 'var(--gray500)'}}>{props.label}</p>
          {props.required && <p className="selectorSearcher-container-label-requiered bold">&nbsp;*</p>}
        </div>
      }
      <div 
        id={props.id}
        className="selectorSearcher-container-selector" 
        onClick={() => {
          if(!props.selectedItem && !props.disabled) {
            setOpenList(true)
          }
        }}
        style={{
          borderBottomLeftRadius: openList && completeList ? 0 : 15,
          borderBottomRightRadius: openList && completeList? 0 : 15,
          backgroundColor: props.disabled ? 'var(--gray50)' : props.selectedItem ? 'var(--primary200)' : 'var(--white)',
          border: props.shake ? '1.5px solid var(--statusErrorPrimary)' : props.disabled ? '1px solid var(--gray200)' : '1px solid var(--gray200)'
        }}
      >
        {props.selectedItem ? 
          <b 
            className="selectorSearcher-container-selector-value regular medium"
            style={{ color: props.disabled ? 'var(--gray300)' : 'var(--gray700)' }}
          >
            {props.selectedItem}
          </b>
          : props.noSearch ? 
            <b
              className="selectorSearcher-container-selector-placeholder light medium"
              style={{ color: props.shake ? 'var(--statusErrorPrimary)' : props.disabled ? 'var(--gray300)' : 'var(--gray300)' }}
              onClick={() => {
                setOpenList(true)
              }}
            >{props.placeholder}</b>
            :
            <input 
              id="Selector"
              value={textSearch}
              disabled={props.disabled} 
              className={props.shake ? "selectorSearcher-container-selector-inputShake" : props.disabled ? "selectorSearcher-container-selector-inputDisabled" : "selectorSearcher-container-selector-input"}
              placeholder={props.placeholder}
              onChange={(e) => {
                if(props.onChange) {
                  props.onChange(e.target.value)
                  setTextSearch(e.target.value)
                } else {
                  filterByText(e.target.value)
                }
              }}
              onKeyDown={(e: any) => {
                if(e.keyCode === 40 && completeList?.length - 1 !== preSelectedItemIndex) { // arrow down
                  setPreSelectedItemIndex(preSelectedItemIndex + 1)
                } else if (e.keyCode === 38 && preSelectedItemIndex >= 0) { // arrow up
                  setPreSelectedItemIndex(preSelectedItemIndex - 1)
                } else if (e.keyCode === 13 && preSelectedItemIndex >= 0) {
                  handleItemSelected(completeList[preSelectedItemIndex])
                }
              }}
              onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
                setOpenList(true)
              }}
              onBlur={() => setOpenList(false)}
            />
        }
        {props.loading ?
            <span className="selectorSearcher-container-selector-loader"></span>
          : !completeList ? 
          <div 
            id='Delete item'
            className="selectorSearcher-container-selector-lupa"
            style={{
              borderBottomRightRadius: openList ? 0 : 25,
            }}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'transparent'}}>close</span>
          </div>
          : props.selectedItem ? 
          <div 
            id='Delete item'
            className="selectorSearcher-container-selector-lupa"
            style={{
              borderBottomRightRadius: openList ? 0 : 25,
              cursor: 'pointer'
            }}
            onClick={() => {
              if(!props.disabled) {
                setOpenList(true)
                props.deleteSelectedItem()
                filterByText('')
              }
            }}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.disabled ? 'transparent' : 'var(--primary500)' }}>close</span>
          </div>
          :
          <div 
            className="selectorSearcher-container-selector-lupa"
            style={{
              borderBottomRightRadius: openList ? 0 : 25,
              transform: openList ? 'rotate(180deg)' : 'rotate(0deg)',
              cursor: 'pointer'
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              setOpenList(!openList)
              filterByText('')
            }}
          >
            <span className="material-symbols-outlined" style={{ fontSize: 24, color: props.shake ? 'var(--statusErrorPrimary)' : 'var(--primary500)' }}>keyboard_arrow_down</span>
          </div>
        }
      </div>
      <div 
        className="selectorSearcher-container-list"
        style={{
          maxHeight: openList ? 250 : 0,
          width: document.getElementById(props.id)?.offsetWidth
        }}
      >
        {completeList?.map((a: any, index: any) => (
          <b
            id={a.label + ' ' + a.subTitle + ' item'}
            key={index}
            className="selectorSearcher-container-list-listItemText regular large"
            onClick={() => handleItemSelected(a)}
          >
            {a.codigoPostal ? a.nombre + ' (' + a.codigoPostal + ')': a.nombre ? a.nombre : props.cp ? a.descripcion?.toUpperCase() + ' (' + a.codigoPostal + ')' : a.label ? a.label?.toUpperCase() : a.descripcion ? a.descripcion?.toUpperCase() : a.name?.toUpperCase()}
          </b>
        ))}
      </div>
    </div>
  );
} 

export default SelectorSearcher;