import React, { useEffect, useState } from 'react'
import './Oferta.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import { 
  checkOutOffer,
  getRestrictions,
  getOffer,
  categoriaSelectedChange,
  getUserAuth,
  actionGetQuoteErrors
} from '../../actions';
import { connect, useDispatch } from 'react-redux';
import OfferPlan from './OfferPlan/OfferPlan';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import VerMasModal from '../../components/modalComponents/VerMasModal/VerMasModal';
import { OFFER_DATA } from '../../actions/types';

let isTabletOrMobile;
const Oferta = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [verMasAlert, setVerMasAlert] = useState<boolean>(false);
  const [verMasInfo, setVerMasInfo] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const [admin, setAdmin] = useState<boolean>(false);
  const [packages, setPackages] = useState<any>(null);
  const [insurers, setInsurers] = useState<any>(null);
  const [insurerSelected, setInsurersSelected] = useState<any>(null);
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
  const [quoteErrors, setQuoteErrors] = useState<any>(null);
  
  const handleUrlParams = () => {
    const offer = searchParams.get('offer');
    const cId = searchParams.get('cId');
    let admin: any = searchParams.get("admin")

    if (admin) setAdmin(true)

    if(cId && offer) {
      props.categoriaSelectedChange(cId)
      if(!props.offerData) {
          props.getOffer(cId, offer, null)
      }
    }
  };

  useEffect(() => {
    handleUrlParams()
    dispatch(getUserAuth());
    if(window.location.hostname != 'localhost') {
      window.parent.postMessage('Not-Emitting', appBaseIframe)
      setTimeout(() => {
      }, 5000)
    } else {
      window.parent.postMessage('Not-Emitting', 'http://localhost:3000')
      setTimeout(() => {
      }, 5000)
    }
    return () => {
      // Execute your function or cleanup logic here
      if(window.location.hostname != 'localhost') {
        window.parent.postMessage('Not-CanSaveQuote', appBaseIframe)
        setTimeout(() => {
        }, 5000)
      } else {
        window.parent.postMessage('Not-CanSaveQuote', 'http://localhost:3000')
        setTimeout(() => {
        }, 5000)
      }
    };
  }, [])

  useEffect(() => {
    if(props.offerData) {
      if(window.location.hostname != 'localhost') {
        window.parent.postMessage('CanSaveQuote: ' + props.offerData?.id, appBaseIframe)
        setTimeout(() => {
        }, 5000)
      } else {
        window.parent.postMessage('CanSaveQuote: ' + props.offerData?.id, 'http://localhost:3000')
        setTimeout(() => {
        }, 5000)
      }
    }
  }, [props.offerData])

  const removeRepeatedObjects = (array, property) => {
    return array.filter((obj, index, self) =>
      index === self.findIndex((o) => o[property] === obj[property])
    );
  };

  useEffect(() => {
    if(quoteErrors) {
      const newInsurers = [...insurers];
      quoteErrors.map((error: any) => {
        const newInsurer = {};
        newInsurer['error'] = true
        newInsurer['errorMessage'] = error?.error
        newInsurer['id'] = error?.insurerId
        newInsurer['image'] = error?.insurerImg
        newInsurer['name'] = error?.insurerName
        newInsurers.push(newInsurer)
      })
      setInsurers(newInsurers)
    }
  }, [quoteErrors])

  useEffect(() => {
    if(props.offerData) {
      let insurerList: any = [];
      props.offerData?.packages?.map((a: any) => {
        insurerList.push(a?.insurer)
      })
      setInsurersSelected(insurerList[0]?.id)
      setInsurers(removeRepeatedObjects(insurerList, 'id'))
    }
  }, [props.offerData])

  useEffect(() => {
    if(props.offerData) actionGetQuoteErrors(setQuoteErrors, props.offerData?.quoteId)
  }, [props.offerData])
  
  useEffect(() => {
    if(insurers && insurerSelected) {
      let packagesList = [...props.offerData?.packages];
      packagesList = packagesList?.filter((a: any) => a?.insurer?.id === insurerSelected)
      packagesList.sort((a, b) => {
        if (/^M/i.test(a.description) && !/^M/i.test(b.description)) {
          return -1; // a should come before b
        } else if (!/^M/i.test(a.description) && /^M/i.test(b.description)) {
          return 1; // b should come before a
        } else {
          return 0; // no change in order
        }
      });
      setPackages(packagesList)
    }
  }, [insurers, insurerSelected])

  const verMasModal = () => {
    return (
      <Modal
        isOpen={verMasAlert}
        onRequestClose={() => setVerMasAlert(false)}
        style={{
          content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '80%' : '50%',
              padding: '20px'
          },
          overlay: { backgroundColor: 'rgba(255, 255, 255, 0.5)' }
        }}
      >
        <VerMasModal
          info={verMasInfo}
          close={() => setVerMasAlert(false)}
        />
      </Modal>
    )
  }

  const getInsurerLogoWidth = (id: number) => {
    switch(id) {
      case 17:
        return '80%';
      case 18:
        return '70%';
      case 40:
        return '30%';
      default:
        return '80%';
    }
  }

  return (
    <div className="oferta-container">
      {!admin && <Header home colored special />}
      <div 
        className="oferta-container-volver" 
        onClick={() => {
          dispatch({ type: OFFER_DATA, payload: null });
          const adminN = admin ? '&admin=True' : ''
          navigate('/cotizar?cId=' + props.categoriaSelected + '&quote=' + props.offerData?.quoteId + adminN)
        }}
      >
        <span className="material-symbols-outlined" style={{ color: 'var(--primary500)', fontSize: 18 }}>chevron_left</span>
        <b className="oferta-container-volver-text bold medium">Volver</b>
      </div>
      <div className="oferta-container-main">
        <div className="oferta-container-main-product">
          <b className="oferta-container-main-product-text regular medium">{props.offerData?.product?.marca}</b>
          <b className="oferta-container-main-product-symbol regular medium">&nbsp;{' > '}&nbsp;</b>
          <b className="oferta-container-main-product-text regular medium">{props.offerData?.product?.modelo}</b>
          <b className="oferta-container-main-product-symbol regular medium">&nbsp;{' > '}&nbsp;</b>
          <b className="oferta-container-main-product-text regular medium">{props.offerData?.product?.title}</b>
        </div>
        {insurers && 
          <div className="oferta-container-main-insurers">
            <h6 className="oferta-container-main-insurers-title regular">Visualizá los planes según la aseguradora que elijas</h6>
            <div className="oferta-container-main-insurers-list">
              {insurers?.map((a: any) => (
                <div 
                  className="oferta-container-main-insurers-list-item"
                  onClick={() => setInsurersSelected(a.id)}
                  style={{
                    border: a.id === insurerSelected ? '1.5px solid var(--primary500)' : 'none',
                    opacity: a.error ? 0.6 : 1
                  }}
                >
                  <img className="oferta-container-main-insurers-list-item-img" style={{ width: getInsurerLogoWidth(a.id) }} src={a.image}/>
                </div>
              ))}
            </div>
          </div>
        }
        {packages?.length > 0 ?
          <div className="oferta-container-main-planList">
            {packages?.map((a: any, index: any) => (
              <OfferPlan
                key={index}
                data={a}
                title={a.description}
                per='/mes'
                favorite={/^M/i.test(a?.description)}
                index={index}
                seeMore={() => props.getRestrictions(props.categoriaSelected, props.offerData?.id, props.offerData?.packages[0]?.id, setVerMasAlert, setVerMasInfo)}
                contratar={() => props.checkOutOffer(props.offerData?.id, props.offerData?.product?.categoryId, a.id, navigate, props.countryId, null, admin, appBaseIframe)}
              />
            ))}
          </div>
          : insurers?.length > 0 &&
          <div className="oferta-container-main-emptyList">
            <p className="oferta-container-main-emptyList-text regular">No encontramos planes disponibles para la aseguradora seleccionada.</p>
            <p className="oferta-container-main-emptyList-errorText regular">{insurers?.filter((a: any) => a.id === insurerSelected)[0]?.errorMessage?.replace(/[^a-zA-Z0-9 ]/g, '')}</p>
          </div>
        }
      </div>
      {verMasModal()}
    {!admin && <Footer/>}
    </div>
  )
}

const mapStateToProps = (state: any) => {
    return {
      countryId: state.general.countryId,
      offerData: state.mainCotizar.offerData,
      userData: state.user.userData,
      categoriaSelected: state.cotizar.categoriaSelected,
    };
};

export default connect(mapStateToProps, {
    checkOutOffer,
    getRestrictions,
    getOffer,
    categoriaSelectedChange,
    getUserAuth
})(Oferta);